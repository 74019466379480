import { CheckCircleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/vue/20/solid'

export default {
  base: 'mb-6 rounded-md p-4',
  status: {
    success: 'bg-green-50',
    info: 'bg-sky-50',
    error: 'bg-red-50',
  },
  iconColor: {
    success: 'text-green-400',
    info: 'text-sky-400',
    error: 'text-red-400',
  },
  icons: {
    success: CheckCircleIcon,
    info: InformationCircleIcon,
    error: XCircleIcon,
  },
  default: {
    status: 'success',
  },
} as const
