<script setup lang="ts">
import type { MessageStatus } from '~~/types/base/Message'
import ui from '~/ui/base/message'

const props = withDefaults(defineProps<{ status?: MessageStatus }>(), {
  status: ui.default.status,
})

const alertClass = computed(() => {
  return cn(ui.base, ui.status[props.status])
})

const Icon = computed(() => ui.icons[props.status])
const iconColor = computed(() => ui.iconColor[props.status])
</script>

<template>
  <div :class="alertClass">
    <div class="flex">
      <div class="shrink-0">
        <component :is="Icon" :class="iconColor" class="size-5" aria-hidden="true" />
      </div>
      <div class="ml-3 text-sm">
        <slot />
      </div>
    </div>
  </div>
</template>
